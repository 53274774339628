.parameters-page .card {
  margin-bottom: 20px;
}

.unsubscribeButton, .signOutButton, .editProfileButton, .saveButton {
  background-color: #89023E;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 10px 5px;
  cursor: pointer;
  font-size: 16px;
}

.unsubscribeButton:hover, .signOutButton:hover, .editProfileButton:hover, .saveButton:hover {
  background-color: #99122E;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80%;
  max-width: 800px;
  height: 80%;
  max-height: 600px;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overlay {
  z-index: 5000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.close-button {
  background-color: #89023E;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin-bottom: 20px;
}

.close-button:hover {
  background-color: #99122E;
}

.profile-edit-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.profile-edit-form label {
  font-size: 16px;
}

.profile-edit-form input,
.profile-edit-form textarea {
  padding: 10px;
  width: 90%;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.link-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.link-container a {
  height: 100%;
  background-color: #f4f4f4;
  color: #222;
  text-decoration: none;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  word-break: break-all;
}

.link-container button {
  height: 100%;
  padding: 5px 10px;
  background-color: #89023E;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.qr-code-container {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.qr-code-container button {
  width: 50%;
  height: 100%;
  padding: 5px 10px;
  background-color: #89023E;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.qr-code-container svg {
  width: 40%;
  border-radius: 10px;
}

.iframe-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.iframe-container code {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  word-break: break-all;
}

.iframe-container button {
  padding: 5px 10px;
  background-color: #89023E;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
